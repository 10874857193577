import React from 'react';

import {Paragraph} from '@components/typography';
import {Link} from '@components/Link';
import {styled} from '@styles';
import Image from '@components/Image';

export interface LeadershipThumbnailImageProps {
  image: {
    url: string;
  };
  name: string;
  position: string;
  url: string;
}

const LeadershipImageContainer = styled.div.withConfig({
  componentId: 'leadershipThumbnailLeadershipImageContainer'
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding-bottom: 4em;
  width: 36vw;

  .narrow & {
    width: 40vw;
  }

  .wide & {
    width: 28vw;
    padding-bottom: 0;
  }

  .extraWide & {
    width: 20vw;
    max-width: 300px;
  }
`;

const TextContainer = styled.div.withConfig({
  componentId: 'leadershipThumbnailImageTextContainer'
})`
  height: 15%;
  padding-top: 3px;
`;

const ImageContainer = styled.figure.withConfig({
  componentId: 'leadershipThumbmailImageContainer'
})`
  height: auto;
  width: 100%;
  margin: 0;

  .extraWide & {
    height: 85%;
  }
`;

const Name = styled(Paragraph).withConfig({
  componentId: 'leadershipThumbnailImageName'
})`
  margin: 0;
  padding: 10px 0;
  font-weight: 800;
`;

const Position = styled(Paragraph).withConfig({
  componentId: 'leadershipThumbnailImagePosition'
})`
  margin: 0;
  font-size: 0.8em;
`;

const StyledLink = styled(Link).withConfig({
  componentId: 'leadershipThumbnailImageLink'
})`
  height: 100%;
  width: 100%;
`;

export default function LeadershipThumbnailImage({
  image,
  name,
  position,
  url,
}: LeadershipThumbnailImageProps): JSX.Element | null {
  /**
   * TODO: when are we returning null? No image? No text?
   */
  return (
    <LeadershipImageContainer>
      <StyledLink href={url} aria-label={`${name}. ${position}`}>
        {image && (
          <ImageContainer>
            <Image childImageSharp={image.childImageSharp} />
          </ImageContainer>
        )}
        <TextContainer>
          {name && <Name center>{name}</Name>}
          {position && <Position center>{position}</Position>}
        </TextContainer>
      </StyledLink>
    </LeadershipImageContainer>
  );
}

export {default as LeadershipGrid} from './LeadershipGrid';
