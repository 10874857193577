import React, {useRef} from 'react';
import useIntersection from '@hzdg/use-intersection';
import {useTrail, config, animated} from 'react-spring';

import LeadershipThumbnailImage, {LeadershipThumbnailImageProps} from './';

import {styled} from '@styles';
import {
  useContainerSize,
  ContainerSize,
  BreakpointConfig,
} from '@components/layout/ResponsiveContainer';

interface LeadershipGridProps {
  leaders: LeadershipThumbnailImageProps[];
}

const LeadershipGridContainer = styled.div.withConfig({
  componentId: 'leadershipGridContainer'
})`
  display: grid;
  grid-template-columns: repeat(auto-fit, 25%);
  max-width: 1300px;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin: 0 auto;
  transform: translateY(130px);
  padding-bottom: 130px;

  //
  //
  // additional grid styles accounting for unique placement of first leadership position
  //
  & > div {
    grid-column: span 2;
  }

  // first child is full width and second child starts on new line
  & > div:first-child {
    grid-column: 2 / span 2;

    .wide & {
      grid-column: -3 / span 2;
      margin-bottom: -11em;
    }   
    .extraWide & {
      margin-bottom: 0;
      grid-column: 2 / span 2;
    } 
  }
  & > div:nth-child(2) {
    grid-column: 1 / span 2;
  }
  & > div:last-child {
    padding-bottom: 0;
  }

  .wide & {
    // margin: -18em auto 10em;
    // grid-template-columns: repeat(auto-fit, 16.6665%);
    margin: -18em auto 10em;
    grid-template-columns: repeat(auto-fit, 16.6665%);
  }

  .extraWide & {
   // grid-template-columns: repeat(auto-fit, 25%);
    margin-top: -11em;
    transform: translateY(50px);
    grid-template-columns: repeat(auto-fit, 12.5%);
  }

  .ludicrousWide & {
    transform: translateY(-20px);
    margin-top: -3em;
  }
`;

const LeadershipGridCellContainer = styled.div.withConfig({
  componentId: 'leadershipGridCellContainer'
})`
  margin-bottom: ${({marginBottom}: {marginBottom: number}) =>
    `-${marginBottom}px`};
  display: block;
  padding-bottom: 0;

  .wide & {
    padding-bottom: 75px;
  }
`;

const AnimatedContainer = animated(LeadershipGridCellContainer);

function chunkArray(
  orgArray: LeadershipThumbnailImageProps[],
  chunkSize: number,
) {
  let index = 0;
  let arrayLength = orgArray.length;
  let tempArray = [];
  for (index = 0; index < arrayLength; index += chunkSize) {
    let myChunk = orgArray.slice(index, index + chunkSize);
    tempArray.push(myChunk);
  }
  return tempArray;
}

function normalizeArrayForSize(
  items: LeadershipThumbnailImageProps[],
  numberOfColumns: number,
) {
  const multiplier = 200;

  // Ensure items array is not empty and the first item is not null or undefined
  if (items.length > 0 && items[0]) {
    // Make the first item a marginBottom of 0
    items[0].marginBottom = 0;
  }

  // Extra logic to have first position with unique positioning
  const chunked = chunkArray(items.slice(1), numberOfColumns);
  chunked.forEach(arr => {
    arr.forEach((item, index) => {
      // Ensure item is not null or undefined before setting marginBottom
      if (item) {
        item.marginBottom = index * multiplier;
      }
    });
  });

  // Ensure the first item is not null or undefined before adding it to the flattened array
  const flattened = items[0] ? [items[0], ...chunked.reduce((a, b) => a.concat(b), [])] : chunked.reduce((a, b) => a.concat(b), []);
  return flattened;
}

function assignMarginBottom(
  items: LeadershipThumbnailImageProps[],
  size: ContainerSize<BreakpointConfig>,
) {
  const {wide, extraWide, ludicrousWide} = size;

  if (extraWide) {
    return normalizeArrayForSize(items, 4);
  } else if (wide) {
    return normalizeArrayForSize(items, 3);
  } else {
    return normalizeArrayForSize(items, 2);
  }
}

export default function LeadershipGrid({
  leaders,
}: LeadershipGridProps): JSX.Element | null {
  if (!leaders || leaders.length === 0) return null;
  const size = useContainerSize();

  function isRefIntersecting(ref: React.RefObject<HTMLElement>): boolean {
    const isIntersecting = useIntersection(ref, {threshold: 0.8});
    return Boolean(isIntersecting);
  }

  const mappedItems = assignMarginBottom(leaders, size);

  const elRef = useRef(mappedItems.map(item => React.createRef()));

  const [trail, set] = useTrail(mappedItems.length, () => ({
    config: config.stiff,
    opacity: 1,
    y: -100,
  }));

  return (
    <LeadershipGridContainer>
      {trail &&
        trail.length > 0 &&
        trail.map(({y, opacity, ...rest}, index) => {
          return (
            <AnimatedContainer
              marginBottom={mappedItems[index].marginBottom || 0}
              key={index}
              ref={elRef.current[index]}
              style={{
                transform: y.interpolate(y => `translateY(${y}px)`),
                opacity,
                ...rest,
              }}
            >
              <LeadershipThumbnailImage {...mappedItems[index]} />
            </AnimatedContainer>
          );
        })}
    </LeadershipGridContainer>
  );
}
