import React, {Fragment, useRef} from 'react';
import {graphql} from 'gatsby';

import Meta from '@components/Meta';
import {ResponsiveContainer, FontScale} from '@components/layout';
import {
  LatestSection,
  SubNavSection,
  StyledSection,
} from '@components/sections';
import {usePageContext} from '@components/Page';
import {BodySectionCallout} from '@components/SectionCallout';
import {LeadershipGrid} from '@components/LeadershipThumbnailImage';
import getDataFromBlockTypes from '@util/getDataFromBlockTypes';
import {ThemeProvider, styled, Colors} from '@styles';
import {detailPagesData} from '@components/Subnavigation/detailPagesData';
// import ToggleAnchorLink from '@components/LeadershipThumbnailImage/ToggleAnchorLink';

const LatestStyledSection = styled(LatestSection).withConfig({
  componentId: 'leadershipLatest'
})`
  background: ${Colors.TintedFogLight};
`;

const ContentContainer = styled.div.withConfig({
  componentId: 'leadershipContentContainer'
})`
  width: 100%;
  padding-top: 150px;
`;

const HeaderContainer = styled.div.withConfig({
  componentId: 'leadershipHeaderContainer'
})`
  position: initial;
  // max-width: 558px;
  width: 100%;
  left: 0;
  margin-bottom: 2em;

  .wide & {
    position: relative;
    max-width: calc(50vw - 4em);
  }

  .extraWide & {
    margin-bottom: 0;
    margin-left: auto;
  }
`;

const LeadershipSections = styled.div.withConfig({
  componentId: 'leadershipLeadershipSections'
})<{bgImage: string}>`
  margin: 0;
  background-image: ${({bgImage}) => `url(${bgImage})`};
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 100px;

  .wide & {
    margin: 0;
    padding-top: 150px;
  }

  .extraWide & {
    padding-top: 130px;
  }
`;

export default function Leadership({data}): JSX.Element {
  usePageContext({shouldInvertMenuColors: true});
  const {cms} = data;
  const {page} = cms;
  const {body, searchDescription, seoTitle, parentPage} = page;
  const {leader} = getDataFromBlockTypes(body);
  const {headerBlock: leadershipHeaderBlock, leadersBlock} = leader;
  // const {
  //   headerBlock: directorsHeaderBlock,
  //   leadersBlock: directorsBlock,
  // } = director;
  const leadersRef = useRef(null);
  // const directorsRef = useRef(null);

  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      <ThemeProvider
        theme={{
          minPadding: '2em',
          bg: Colors.Midnight,
          fg: Colors.White,
        }}
      >
        <ResponsiveContainer as={FontScale}>
          {/* Subnav */}
          <SubNavSection
            filter={false}
            categories={detailPagesData}
            backLinkText={parentPage && parentPage.title}
            backLinkUrl={parentPage && parentPage.url}
          />
          {/*<ToggleAnchorLink
            leadersRef={leadersRef}
            directorsRef={directorsRef}
          />*/}
          <ThemeProvider
            theme={{
              maxPadding: '2em',
              bg: Colors.Transparent,
              fg: Colors.Charcoal,
            }}
          >
            <LeadershipSections bgImage={data.bgImage.publicURL}>
              <ThemeProvider
                theme={{
                  maxPadding: '0 2em',
                  bg: Colors.Transparent,
                  fg: Colors.Charcoal,
                }}
              >
                <StyledSection id={`leaders`} ref={leadersRef}>
                  <ContentContainer>
                    <HeaderContainer>
                      <BodySectionCallout headerBlock={leadershipHeaderBlock} />
                    </HeaderContainer>
                  </ContentContainer>
                  <LeadershipGrid leaders={leadersBlock} />
                </StyledSection>
              </ThemeProvider>
            </LeadershipSections>
          </ThemeProvider>

          {/* Blog */}
          <LatestStyledSection />
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
}

export const query = graphql`
  fragment LeadershipFragment on CMS_LeaderIndexPage {
    seoTitle
    searchDescription
    parentPage {
      title
      url
    }
    body {
      ... on CMS_LeaderIndexLeaderBlock {
        __typename
        blockType
        headerBlock {
          header
          subheader
        }
        leadersBlock {
          slug
          url
          name
          position
          image {
            ...cmsFluidImageFragment
          }
        }
      }
    }
  }
  query LeadershipQuery {
    cms {
      page(url: "/home/about/leadership/") {
        ...LeadershipFragment
      }
    }
    bgImage: file(relativePath: {eq: "2U-Background-Light-Gray.svg"}) {
      publicURL
    }
  }
`;
